import Guarantees from "@trades-org/waas-component-library/dist/components/organisms/guarantees/guarantees"
import AboutUs from "@trades-org/waas-component-library/dist/components/organisms/about-us/about-us"
import TeamMembers from "@trades-org/waas-component-library/dist/components/organisms/team-members/team-members"
import Footer from "@trades-org/waas-component-library/dist/components/organisms/footer/footer"
import Header from "@trades-org/waas-component-library/dist/components/organisms/header/header"
import Hero from "@trades-org/waas-component-library/dist/components/organisms/hero/hero"
import { FC } from "react"
import { ComponentsConfig } from "./components-config"
import { NewHorizonAboutPage } from "./new-horizon.types"

const AboutHelper: FC<NewHorizonAboutPage> = ({
    headerData,
    qualificationsData,
    footerData,
    aboutUsData,
    teamMembersData,
    heroFooterData,
}) => {
    const {
        headerData: headerDataSettings,
        aboutUsData: aboutUsDataSettings,
        heroFooterData: heroFooterDataSettings,
        guaranteesData: guaranteesDataSettings,
        teamMembersData: teamMemberDataSettings,
        footerData: footerDataSettings,
    } = ComponentsConfig.aboutPage

    return (
        <main>
            <Header {...headerData} {...headerDataSettings} />
            <AboutUs {...aboutUsData} {...aboutUsDataSettings} />
            {qualificationsData && qualificationsData.guarantees && qualificationsData.guarantees.length > 0 && (
                <Guarantees {...qualificationsData} {...guaranteesDataSettings} />
            )}
            {teamMembersData && teamMembersData.teamMemberData && teamMembersData.teamMemberData.length > 0 && (
                <TeamMembers {...teamMembersData} {...teamMemberDataSettings} />
            )}
            <Hero {...heroFooterData} {...heroFooterDataSettings} />
            <Footer {...footerData} {...footerDataSettings} />
        </main>
    )
}

export default AboutHelper
